@import url(https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

html {
  box-sizing: border-box;
  /* overflow: hidden; */
}

body {
  font-family: "Roboto", sans-serif;
  color: rgb(100, 100, 100);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
